(function () {
    'use strict';

    /**
     aflToolGrid Directive
     Intended to be a component, but usage of nested directives
     require compile procedure to pass the argument
     */
    angular.module('aerosApp').directive('aflToolGrid', aflToolGrid);

    aflToolGrid.$inject = ['AFL_GRID', '$state', 'aflGridFilterFactory'];
    function aflToolGrid(AFL_GRID, $state, aflGridFilterFactory) {
        return {
            restrict: 'E',
            template: `<afl-grid 
                            model-type="toolLibrary"
                            license="license"
                            series="series"
                            columns="columns" 
                            filters="filters" 
                            on-reload="onReload()" 
                            default-filters="defaultFilters"></afl-tool-grid>`,
            compile: function () {
                return {pre: link, post: _.noop};
            },
            scope: {
                license: '=',
                cols: '=',
                rows: '=',
                widths: '=',
                actionWidth: '=',
                onReload: '&'
            }
        };

        function link(scope) {
            var filterFactory = aflGridFilterFactory($state.params.toolType, 'toolLibrary');
            scope.columns = generateListColumns(scope.cols, scope.widths);
            scope.filters = filterFactory.getFilters();
            scope.defaultFilters = filterFactory.getDefaults();
            setSeries(scope.rows);
            scope.$watch('rows', setSeries);

            function setSeries(rows) {
                scope.series = generateListRows(rows, scope.cols);
            }
        }

        function generateListColumns(list, widths) {

            var defaultWidth = list.length ?
                AFL_GRID.getDefaultWidth(list.length + 2) :
                AFL_GRID.getDefaultWidth(3);

            var itemColumns = list.length ?
                _.map(list, createGridItem(defaultWidth, widths)) :
                [{
                    headerName: 'Tool Type',
                    field: 'toolType',
                    width: getWidth({defaultWidth, widths, key: 0})
                }];

            return _.chain([]).union(
                [{
                    headerName: 'Tool Instance',
                    field: 'name',
                    width: getWidth({defaultWidth, widths, key: 0})
                }, {
                    headerName: 'ID',
                    field: 'uuid',
                    hide: true
                }],
                itemColumns,
                [{
                    headerName: 'Actions',
                    templateUrl: '/static/templates/tools/aflToolGridActions.html',
                    suppressFilter: true,
                    width: AFL_GRID.defaultActionWidth
                }]
            ).uniqBy('headerName').// TODO: figure out why duplicating
            value();

            function createGridItem(defaultWidth, widths) {
                return function(item, key) {
                    return item["as-is"]
                        ? {
                            headerName: item.name,
                            field: item.id,
                            width: getWidth({
                                defaultWidth,
                                widths,
                                key
                            })
                        }
                        : {
                            headerName: item.name + ' Preset',
                            field: [item.id + '.name'],
                            width: getWidth({
                                defaultWidth,
                                widths,
                                key
                            })
                        };
                };
            }
        }

        function getWidth({defaultWidth, widths, key}) {
            return (widths && widths[key] !== undefined) ? widths[key]:defaultWidth;
        }

        function generateListRows(rows, columns) {
            return _.map(rows, function (row) {
                return _.assign(row, getProps(row));
            });

            function getProps(model) {
                return _.chain(model).pick(_.map(columns, 'id')).mapValues('name').mapKeys(function (v, k) {
                    return k + 'name'
                }).value();
            }
        }
    }
}());
